// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-OperationHistory-item {\n  word-wrap: break-word;\n  word-break: break-all;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n.x-OperationHistory-item.x-OperationHistory-selected,\n.x-OperationHistory-item:hover {\n  background-color: #780000;\n}\n.x-OperationHistory-item .x-OperationHistory-buttons {\n  display: none;\n}\n.x-OperationHistory-item:hover .x-OperationHistory-buttons {\n  display: initial;\n}\n.x-OperationHistory-buttons {\n  display: none;\n  font-size: 13px;\n  margin-left: auto;\n}\n.x-OperationHistory-buttons > * {\n  padding: 0 3px;\n}\n.x-OperationHistory-buttons > *.x-OperationHistory-danger:hover {\n  color: red;\n}\n.x-OperationHistory-buttons > i:hover {\n  color: yellowgreen;\n}\n.x-OperationHistory-opLabel {\n  text-transform: uppercase;\n}\n", ""]);
// Exports
exports.locals = {
	"item": "x-OperationHistory-item",
	"selected": "x-OperationHistory-selected",
	"buttons": "x-OperationHistory-buttons",
	"danger": "x-OperationHistory-danger",
	"opLabel": "x-OperationHistory-opLabel"
};
module.exports = exports;
