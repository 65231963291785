// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Wizard-errorMessage {\n  color: lightgoldenrodyellow;\n  white-space: pre-line;\n}\n.x-Wizard-errorCode {\n  font-size: 9px;\n  font-style: italic;\n}\n", ""]);
// Exports
exports.locals = {
	"errorMessage": "x-Wizard-errorMessage",
	"errorCode": "x-Wizard-errorCode"
};
module.exports = exports;
