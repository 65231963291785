// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-ObjectExplorer-selected {\n  background-color: #f3fb8a;\n  color: black;\n}\n.x-ObjectExplorer-modelLabel {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n}\n.x-ObjectExplorer-hint {\n  font-style: italic;\n  color: #bfbfbf;\n}\n", ""]);
// Exports
exports.locals = {
	"selected": "x-ObjectExplorer-selected",
	"modelLabel": "x-ObjectExplorer-modelLabel",
	"hint": "x-ObjectExplorer-hint"
};
module.exports = exports;
