// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-TabSwitcher {\n  color: hsl(0, 0%, 65%);\n}\n.x-TabSwitcher-tab {\n  padding: 4px 4px 4px 7px;\n  cursor: pointer;\n  border-right: 1px solid hsl(200, 6%, 38%);\n  display: inline-block;\n}\n.x-TabSwitcher-tab:hover {\n  color: hsl(0, 0%, 90%);\n}\n.x-TabSwitcher-tab.x-TabSwitcher-active {\n  background-color: hsl(200, 6%, 17%);\n  color: hsl(0, 0%, 90%);\n}\n.x-TabSwitcher-tab:first-child {\n  border-left: 1px solid hsl(200, 6%, 38%);\n}\n.x-TabSwitcher-expand:hover {\n  color: green;\n}\n.x-TabSwitcher-close:hover {\n  color: red;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-TabSwitcher",
	"tab": "x-TabSwitcher-tab",
	"active": "x-TabSwitcher-active",
	"expand": "x-TabSwitcher-expand",
	"close": "x-TabSwitcher-close"
};
module.exports = exports;
