export const Styles = {
  DEFAULT : {
    lineWidth : 2,
    strokeStyle : "#ffffff",
    fillStyle : "#000000"
  },
  VIRTUAL: {
    lineWidth : 2,
    strokeStyle : "#ffffff88",
    fillStyle : "#00000088"
  }, 
  SERVICE : {
    lineWidth : 0.3,
    strokeStyle : "#ff0000",
    fillStyle : "#FF0000"
  },

  SELECTION : {
    lineWidth : 2,
    strokeStyle : "#ff0000",
    fillStyle : "#FF0000"
  },

  HIGHLIGHT : {
    lineWidth : 2,
    strokeStyle : "#f1ff3a",
    fillStyle : "#f1ff3a"
  },

  TOOL_HELPER : {
    lineWidth : 2,
    strokeStyle : "#00FF00",
    fillStyle : "#00FF00"
  },

  HIGHLIGHT2 : {
    lineWidth : 2,
    strokeStyle : "#ff961f",
    fillStyle : "#ff961f"
  },

  DIM : {
    lineWidth : 1,
    strokeStyle : "#bcffc1",
    fillStyle : "#00FF00"
  },

  ANNOTATIONS: {
    lineWidth : 1,
    strokeStyle : "#101010",
    fillStyle : "#000000"
  },

  BOUNDS : {
    lineWidth : 2,
    strokeStyle : "#fff5c3",
    fillStyle : "#000000"
  },
  PAST: {
    lineWidth : 2,
    strokeStyle : "#fff5c3",
    fillStyle : "#000000"
  },

  FUTURE: {
    lineWidth : 2,
    strokeStyle : "#576a85",
    fillStyle : "#000000"
  },

  FULLY_CONSTRAINED: {
    lineWidth : 2,
    strokeStyle : "#9cd4ff",
    fillStyle : "#2c44bc"
  },


  FULLY_CONSTRAINED_CONSTRUCTION: {
    lineWidth : 2,
    strokeStyle : "#cfba80",
    fillStyle : "#cfba80"
  },

  CONSTRUCTION : {
    lineWidth : 1,
    strokeStyle: "#964B00",
    fillStyle : "#964B00"
  },

  CONSTRUCTION_OF_OBJECT : {
    lineWidth : 1,
    strokeStyle : "#888888",
    fillStyle : "#000000"
  },
  GENERATED: {
    lineWidth : 2,
    strokeStyle : "#c5bbff",
    fillStyle : "#8252bc"
  }
};