// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-AppTabs {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.x-AppTabs-content {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  position: relative;\n}\n.x-AppTabs-content > * {\n  flex: 1;\n}\n.x-AppTabs-contentSwitcher {\n  border-top: 1px solid hsl(200, 6%, 38%);\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-AppTabs",
	"content": "x-AppTabs-content",
	"contentSwitcher": "x-AppTabs-contentSwitcher"
};
module.exports = exports;
