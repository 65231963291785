// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-RadioButtons-radioButton {\n  margin-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"radioButton": "x-RadioButtons-radioButton"
};
module.exports = exports;
