// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-SceneInlineSection-titleBar {\n  background: rgba(0, 0, 0, 0.7);\n  color: white;\n  text-transform: uppercase;\n  padding: 3px 5px;\n  pointer-events: auto;\n  flex: 0 0 ;\n  margin-top: 4px;\n}\n.x-SceneInlineSection-scrollableArea {\n  overflow-y: auto;\n  flex: 1 1;\n  pointer-events: auto;\n}\n.x-SceneInlineSection-delineation {\n  background: rgba(0, 0, 0, 0.5);\n  color: white;\n  padding: 0;\n  pointer-events: auto;\n  flex: 0 0 ;\n  margin-top: 2px;\n}\n", ""]);
// Exports
exports.locals = {
	"titleBar": "x-SceneInlineSection-titleBar",
	"scrollableArea": "x-SceneInlineSection-scrollableArea",
	"delineation": "x-SceneInlineSection-delineation"
};
module.exports = exports;
