// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Stack > * {\n  border-bottom: 1px solid hsl(200, 6%, 38%);\n  padding: 3px 6px;\n  line-height: 1.7;\n}\n.x-Stack > .x-Stack {\n  padding: 0;\n  border-bottom: none;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Stack"
};
module.exports = exports;
