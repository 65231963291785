// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Expressions {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\ntextarea.x-Expressions-script {\n  flex: 1;\n  resize: none;\n  background: inherit;\n  border: none;\n  color: #C4E1A4;\n  padding: 2px;\n  outline: none;\n  font-family: Monaco, monospace;\n}\ntextarea.x-Expressions-script:focus {\n  border: none;\n  background: inherit;\n}\n.x-Expressions-workingArea {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n.x-Expressions-switcher {\n  border-bottom: 1px solid hsl(200, 6%, 38%);\n  width: 100%;\n}\n.x-Expressions-switcher button {\n  padding: 0.3em 1em 0.3em 0.8em !important;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Expressions",
	"script": "x-Expressions-script",
	"workingArea": "x-Expressions-workingArea",
	"switcher": "x-Expressions-switcher"
};
module.exports = exports;
