// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-HeadsUpToolbar-quickButtons {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  flex: 0 0;\n  border-right: 1px solid #8e8e8e;\n  padding-right: 3px;\n}\n.x-HeadsUpToolbar-quickButtons > * {\n  font-size: 16px;\n  padding: 5px 2px;\n}\n.x-HeadsUpToolbar-mainActions {\n  flex: 1 1;\n  display: flex;\n  flex-wrap: wrap;\n}\n.x-HeadsUpToolbar-mainActions svg {\n  width: 24px;\n  height: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"quickButtons": "x-HeadsUpToolbar-quickButtons",
	"mainActions": "x-HeadsUpToolbar-mainActions"
};
module.exports = exports;
