// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Explorer .sectionHeader {\n  margin: 3px;\n  padding: 2px;\n  background-color: #7d7d7dee;\n  border: #595959 1px solid;\n  border-radius: 3px;\n  pointer-events: initial;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Explorer"
};
module.exports = exports;
