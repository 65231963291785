import React from 'react';
import {useStream} from "ui/effects";

export function LoadingBadge() {

  const loaded = useStream(ctx => ctx.streams.lifecycle.projectLoaded);

  if (loaded) {
    return null;
  }

  return <div style={{
    background: 'black',
    padding: '2px 5px',
    right: 0,
    position: 'absolute'
  }}>
    Loading...
  </div>

}