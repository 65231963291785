// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Widget {\n  color: #fff;\n  background-color: rgba(40, 40, 40, 0.95);\n  border: solid 1px #000;\n  border-radius: 5px;\n}\n.x-Widget-flatBottom {\n  border-radius: 5px 5px 0 0;\n}\n.x-Widget-flatTop {\n  border-radius: 0 0 5px 5px;\n}\n.x-Widget-flatRight {\n  border-radius: 5px 0 0 5px;\n}\n.x-Widget-flatLeft {\n  border-radius: 0 5px 5px 0;\n}\n.x-Widget {\n  padding: 5px 5px 10px 15px;\n}\n.x-Widget-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.x-Widget-title {\n  font-size: 16px;\n}\n.x-Widget-headerButtons {\n  font-size: 20px;\n  margin-left: 10px;\n}\n.x-Widget-content {\n  padding-top: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Widget",
	"flatBottom": "x-Widget-flatBottom",
	"flatTop": "x-Widget-flatTop",
	"flatRight": "x-Widget-flatRight",
	"flatLeft": "x-Widget-flatLeft",
	"header": "x-Widget-header",
	"title": "x-Widget-title",
	"headerButtons": "x-Widget-headerButtons",
	"content": "x-Widget-content"
};
module.exports = exports;
