// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-ModelButton {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.x-ModelButton-label {\n  display: flex;\n  align-items: center;\n}\n.x-ModelButton-controls {\n  display: flex;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-ModelButton",
	"label": "x-ModelButton-label",
	"controls": "x-ModelButton-controls"
};
module.exports = exports;
