// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-SymbolButton-symbolButton {\n  cursor: pointer;\n}\n.x-SymbolButton-symbolButton:active {\n  transition: 100ms;\n}\n.x-SymbolButton-neutral {\n  cursor: pointer;\n  color: #fff;\n}\n.x-SymbolButton-neutral:active {\n  transition: 100ms;\n}\n.x-SymbolButton-neutral:hover {\n  color: #EFEFEF;\n}\n.x-SymbolButton-neutral:active {\n  color: #9cdaf7;\n}\n.x-SymbolButton-danger {\n  cursor: pointer;\n  color: #fff;\n}\n.x-SymbolButton-danger:active {\n  transition: 100ms;\n}\n.x-SymbolButton-danger:hover {\n  color: #b00;\n}\n.x-SymbolButton-danger:active {\n  color: #ff2222;\n}\n", ""]);
// Exports
exports.locals = {
	"symbolButton": "x-SymbolButton-symbolButton",
	"neutral": "x-SymbolButton-neutral",
	"danger": "x-SymbolButton-danger"
};
module.exports = exports;
