// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-StageControl {\n  background-color: #0008;\n  color: white;\n  display: flex;\n  padding-left: 3px;\n}\n.x-StageControl > * {\n  padding: 3px;\n  margin: 3px 3px 3px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-StageControl"
};
module.exports = exports;
