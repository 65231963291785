// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-ConstraintExplorer-titleBar {\n  background: rgba(0, 0, 0, 0.7);\n  color: white;\n  text-transform: uppercase;\n  padding: 3px 5px;\n  pointer-events: auto;\n  flex: 0 0 ;\n  margin-top: 4px;\n}\n.x-ConstraintExplorer-scrollableArea {\n  overflow-y: auto;\n  flex: 1 1;\n  pointer-events: auto;\n}\n.x-ConstraintExplorer-button {\n  cursor: pointer;\n}\n.x-ConstraintExplorer-button:hover {\n  background-color: #0074D9;\n}\n.x-ConstraintExplorer-button:active {\n  background-color: #000d7f;\n}\n.x-ConstraintExplorer-objectItem {\n  background-color: rgba(0, 0, 0, 0.6);\n  border: 1px solid #000;\n  border-radius: 5px;\n  margin: 2px 5px;\n  pointer-events: auto;\n  display: flex;\n  align-items: stretch;\n}\n.x-ConstraintExplorer-objectItem.x-ConstraintExplorer-conflicting {\n  background-color: rgba(93, 46, 46, 0.6);\n}\n.x-ConstraintExplorer-objectItem.x-ConstraintExplorer-redundant {\n  background-color: rgba(93, 88, 40, 0.6);\n}\n.x-ConstraintExplorer-objectItem .x-ConstraintExplorer-objectIcon {\n  border-radius: 5px 0 0 5px;\n  padding: 3px 3px;\n  background-color: #606060;\n}\n.x-ConstraintExplorer-objectItem .x-ConstraintExplorer-removeButton {\n  border-radius: 0 5px 5px 0;\n  background-color: #9c9c9c;\n  padding: 0 5px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.x-ConstraintExplorer-objectItem .x-ConstraintExplorer-removeButton:hover {\n  background-color: #0074D9;\n}\n.x-ConstraintExplorer-objectItem .x-ConstraintExplorer-removeButton:active {\n  background-color: #000d7f;\n}\n.x-ConstraintExplorer-objectItem .x-ConstraintExplorer-removeButton:hover {\n  color: red;\n}\n.x-ConstraintExplorer-objectItem .x-ConstraintExplorer-objectTag {\n  display: flex;\n  align-items: center;\n  flex: 1;\n  padding: 0 5px;\n  cursor: pointer;\n}\n.x-ConstraintExplorer-objectItem .x-ConstraintExplorer-objectTag:hover {\n  background-color: #0074D9;\n}\n", ""]);
// Exports
exports.locals = {
	"titleBar": "x-ConstraintExplorer-titleBar",
	"scrollableArea": "x-ConstraintExplorer-scrollableArea",
	"button": "x-ConstraintExplorer-button",
	"objectItem": "x-ConstraintExplorer-objectItem",
	"conflicting": "x-ConstraintExplorer-conflicting",
	"redundant": "x-ConstraintExplorer-redundant",
	"objectIcon": "x-ConstraintExplorer-objectIcon",
	"removeButton": "x-ConstraintExplorer-removeButton",
	"objectTag": "x-ConstraintExplorer-objectTag"
};
module.exports = exports;
