// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-ProjectManager a {\n  text-decoration: none;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-ProjectManager"
};
module.exports = exports;
