// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Field {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n}\n.x-Field-active {\n  background-color: #003f5d;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Field",
	"active": "x-Field-active"
};
module.exports = exports;
