// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Folder-title {\n  border-bottom: 1px solid hsl(200, 6%, 38%);\n  background-color: hsl(200, 6%, 12%);\n  padding: 3px 6px;\n  line-height: 1.7;\n}\n.x-Folder-handle {\n  font-size: 8px;\n  color: hsl(0, 0%, 65%);\n}\n", ""]);
// Exports
exports.locals = {
	"title": "x-Folder-title",
	"handle": "x-Folder-handle"
};
module.exports = exports;
