// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-AuxWidget {\n  color: hsl(0, 0%, 90%);\n  background-color: hsl(200, 6%, 23%);\n  border: solid 1px hsl(200, 6%, 12%);\n  border-radius: 3px;\n}\n.x-AuxWidget-flatBottom {\n  border-radius: 3px 3px 0 0;\n}\n.x-AuxWidget-flatTop {\n  border-radius: 0 0 3px 3px;\n}\n.x-AuxWidget-flatRight {\n  border-radius: 3px 0 0 3px;\n}\n.x-AuxWidget-flatLeft {\n  border-radius: 0 3px 3px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-AuxWidget",
	"flatBottom": "x-AuxWidget-flatBottom",
	"flatTop": "x-AuxWidget-flatTop",
	"flatRight": "x-AuxWidget-flatRight",
	"flatLeft": "x-AuxWidget-flatLeft"
};
module.exports = exports;
