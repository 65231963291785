// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SVG_ICON {\n  stroke: #fff;\n}\n.x-View3d {\n  height: 100%;\n  display: flex;\n}\n.x-View3d-mainArea {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  position: relative;\n}\n#viewer-container {\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  position: absolute;\n}\n.x-View3d-mainLayout {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  pointer-events: none;\n}\n.x-View3d-headsUp {\n  display: flex;\n  flex-direction: column;\n}\n.x-View3d-middleSection {\n  display: flex;\n  align-items: flex-start;\n  flex-grow: 1;\n  overflow: hidden;\n}\n.x-View3d-overlayingPanel {\n  align-self: stretch;\n  display: flex;\n  flex-direction: column;\n}\n.x-View3d-sketcherViewport {\n  flex: 1;\n  position: relative;\n}\n.x-View3d-wizardArea {\n  pointer-events: auto;\n  position: relative;\n}\n.x-View3d-spring {\n  flex-grow: 1;\n}\n.x-View3d-middleRight {\n  height: 100%;\n}\n.x-View3d-bottomStack {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  pointer-events: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-View3d",
	"mainArea": "x-View3d-mainArea",
	"mainLayout": "x-View3d-mainLayout",
	"headsUp": "x-View3d-headsUp",
	"middleSection": "x-View3d-middleSection",
	"overlayingPanel": "x-View3d-overlayingPanel",
	"sketcherViewport": "x-View3d-sketcherViewport",
	"wizardArea": "x-View3d-wizardArea",
	"spring": "x-View3d-spring",
	"middleRight": "x-View3d-middleRight",
	"bottomStack": "x-View3d-bottomStack"
};
module.exports = exports;
