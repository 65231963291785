// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-CreateDatumWizard-rot {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.x-CreateDatumWizard-rotControls {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.x-CreateDatumWizard-rotControls > * {\n  margin-right: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"rot": "x-CreateDatumWizard-rot",
	"rotControls": "x-CreateDatumWizard-rotControls"
};
module.exports = exports;
