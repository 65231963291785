// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-CameraControl-cameraControl {\n  top: -80px;\n  right: 0;\n  position: absolute;\n  width: 80px;\n  height: 80px;\n  pointer-events: none;\n}\n", ""]);
// Exports
exports.locals = {
	"cameraControl": "x-CameraControl-cameraControl"
};
module.exports = exports;
