// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Row {\n  line-height: 1px;\n}\n.x-Row > * {\n  line-height: 1px;\n  margin: 3px;\n  padding: 5px 3px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Row"
};
module.exports = exports;
