// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-ControlBar {\n  display: flex;\n  justify-content: space-between;\n  background-color: rgba(0, 0, 0, 0.5);\n  color: hsl(0, 0%, 80%);\n  white-space: nowrap;\n}\n.x-ControlBar-button {\n  cursor: pointer;\n  padding: 5px 5px 4px 5px;\n  line-height: 1;\n}\n.x-ControlBar-button:hover {\n  background-color: #555;\n}\n.x-ControlBar-left,\n.x-ControlBar-right {\n  display: flex;\n  align-items: center;\n}\n.x-ControlBar-right {\n  flex-direction: row-reverse;\n}\n.x-ControlBar-left .x-ControlBar-button {\n  border-right: 1px solid hsl(200, 6%, 38%);\n  display: flex;\n  align-items: center;\n}\n.x-ControlBar-right .x-ControlBar-button {\n  border-left: 1px solid hsl(200, 6%, 38%);\n}\n.x-ControlBar-button.x-ControlBar-disabled {\n  color: hsl(0, 0%, 65%);\n}\n.x-ControlBar-button.x-ControlBar-disabled:hover {\n  background-color: rgba(0, 0, 0, 0.5);\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-ControlBar",
	"button": "x-ControlBar-button",
	"left": "x-ControlBar-left",
	"right": "x-ControlBar-right",
	"disabled": "x-ControlBar-disabled"
};
module.exports = exports;
