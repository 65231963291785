// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-SelectedModificationInfo-requestInfo {\n  display: flex;\n  margin-bottom: 5px;\n}\n.x-SelectedModificationInfo-requestInfo > * {\n  margin-right: 5px;\n}\n.x-SelectedModificationInfo-pic {\n  margin-right: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"requestInfo": "x-SelectedModificationInfo-requestInfo",
	"pic": "x-SelectedModificationInfo-pic"
};
module.exports = exports;
