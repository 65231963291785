// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "*.x-common-autoMarginLeft {\n  margin-left: auto !important;\n}\n*.x-common-floatRight {\n  float: right;\n}\n.x-common-fullWidth {\n  width: 100%;\n}\n.x-common-dangerColor {\n  color: #b00;\n}\n.x-common-dangerBg {\n  background-color: #b00;\n}\n.x-common-inlineBlock {\n  display: inline-block;\n}\n.x-common-scrollable {\n  overflow: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"autoMarginLeft": "x-common-autoMarginLeft",
	"floatRight": "x-common-floatRight",
	"fullWidth": "x-common-fullWidth",
	"dangerColor": "x-common-dangerColor",
	"dangerBg": "x-common-dangerBg",
	"inlineBlock": "x-common-inlineBlock",
	"scrollable": "x-common-scrollable"
};
module.exports = exports;
