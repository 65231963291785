// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-ActionInfo {\n  display: flex;\n  flex-direction: column;\n  pointer-events: none;\n  padding: 2px 5px;\n  max-width: 400px;\n  color: hsl(0, 0%, 80%);\n}\n.x-ActionInfo > * {\n  padding: 3px 0;\n}\n.x-ActionInfo-hotKey {\n  text-align: right;\n  font-style: italic;\n  color: hsl(0, 0%, 65%);\n  text-transform: capitalize;\n  white-space: nowrap;\n}\n.x-ActionInfo-hint {\n  font-style: italic;\n  color: #E1A4A4;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-ActionInfo",
	"hotKey": "x-ActionInfo-hotKey",
	"hint": "x-ActionInfo-hint"
};
module.exports = exports;
