// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Menu {\n  padding: 5px 0;\n}\n.x-Menu-item {\n  padding: 5px 6px 5px 5px;\n  cursor: pointer;\n  text-transform: capitalize;\n  white-space: nowrap;\n  display: flex;\n  align-items: center;\n}\n.x-Menu-item:hover {\n  background-color: #0074D9;\n}\n.x-Menu-item:active {\n  background-color: #000d7f;\n}\n.x-Menu-item.x-Menu-disabled:hover,\n.x-Menu-item.x-Menu-disbaled:active {\n  background-color: #545454;\n}\n.x-Menu-item .x-Menu-hotKey {\n  color: hsl(0, 0%, 65%);\n  font-size: 9px;\n  padding-left: 14px;\n  flex-grow: 1;\n  text-align: right;\n}\n.x-Menu-item .x-Menu-label {\n  padding-left: 5px;\n  padding-right: 3px;\n}\n.x-Menu-separator {\n  border-top: solid 1px hsl(200, 6%, 48%);\n}\n.x-Menu-disabled {\n  color: hsl(0, 0%, 65%);\n}\n.x-Menu-contextMenu:hover .x-Menu-contextMenuBtn {\n  color: #fff;\n}\n.x-Menu-contextMenuBtn {\n  display: inline-block;\n  padding: 2px 3px;\n  color: #ffffff33;\n}\n.x-Menu-contextMenuBtn:hover {\n  color: #fff;\n}\n.x-Menu-contextMenuBtn:active {\n  color: #7f0807;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Menu",
	"item": "x-Menu-item",
	"disabled": "x-Menu-disabled",
	"disbaled": "x-Menu-disbaled",
	"hotKey": "x-Menu-hotKey",
	"label": "x-Menu-label",
	"separator": "x-Menu-separator",
	"contextMenu": "x-Menu-contextMenu",
	"contextMenuBtn": "x-Menu-contextMenuBtn"
};
module.exports = exports;
