// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-HeadsUpHelper {\n  position: absolute;\n  z-index: 100;\n  left: 280px;\n}\n.x-HeadsUpHelper:empty {\n  border: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-HeadsUpHelper"
};
module.exports = exports;
