// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Toolbar {\n  background-color: rgba(255, 255, 255, 0.5);\n  padding: 3px;\n  border-radius: 5px;\n  display: flex;\n  align-content: center;\n}\n.x-Toolbar.x-Toolbar-flat {\n  border-radius: 0;\n}\n.x-Toolbar-vertical {\n  flex-direction: column;\n}\n.x-Toolbar-button {\n  border-radius: 5px;\n  text-align: center;\n  white-space: nowrap;\n  font-size: 10px;\n  padding: 2px 2px;\n  margin: 1px 5px;\n  color: #555;\n  pointer-events: auto;\n}\n.x-Toolbar-button:hover {\n  cursor: pointer;\n  background-color: #333;\n  color: #fff;\n}\n.x-Toolbar-button.x-Toolbar-disabled {\n  color: #999;\n}\n.x-Toolbar-button.x-Toolbar-disabled:hover {\n  color: #aaa;\n  background-color: #888;\n}\n.x-Toolbar-small > * {\n  font-size: 16px;\n  padding: 5px 2px;\n}\n.x-Toolbar-medium > * {\n  font-size: 20px;\n  padding: 6px 3px;\n}\n.x-Toolbar-splitter {\n  align-self: stretch;\n  width: 1px;\n  background-color: #a0a0a0;\n  margin: 0 2px;\n}\n.x-Toolbar-group {\n  display: flex;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Toolbar",
	"flat": "x-Toolbar-flat",
	"vertical": "x-Toolbar-vertical",
	"button": "x-Toolbar-button",
	"disabled": "x-Toolbar-disabled",
	"small": "x-Toolbar-small",
	"medium": "x-Toolbar-medium",
	"splitter": "x-Toolbar-splitter",
	"group": "x-Toolbar-group"
};
module.exports = exports;
