// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-ButtonGroup {\n  display: flex;\n  justify-content: flex-end;\n}\n.x-ButtonGroup > *:not(:first-child) {\n  margin-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-ButtonGroup"
};
module.exports = exports;
