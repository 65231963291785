// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-Window {\n  position: absolute;\n  z-index: 1;\n  background-color: hsl(200, 6%, 28%);\n  display: flex;\n  flex-direction: column;\n  min-width: 100px;\n  min-height: 20px;\n  pointer-events: auto;\n}\n.x-Window.x-Window-compact {\n  font-size: 11px;\n}\n.x-Window-mandatoryBorder {\n  border: 5px solid hsl(200, 6%, 28%);\n}\n.x-Window-bar {\n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n  background-color: hsl(200, 6%, 23%);\n  cursor: default;\n}\n.x-Window-title {\n  padding: 0.3em 0 0.3em 0.5em;\n  font-size: 1.1em;\n  display: flex;\n  align-items: center;\n}\n.x-Window-controlButtons {\n  display: flex;\n  align-items: stretch;\n}\n.x-Window-button {\n  padding: 0 0.5em;\n  display: flex;\n  align-items: center;\n}\n.x-Window-button:hover {\n  background-color: #2B7D2B;\n  border-color: #2B7D2B;\n}\n.x-Window-button:active {\n  background-color: #113111;\n  border-color: #113111;\n}\n.x-Window-button svg {\n  width: 16px;\n  height: 16px;\n}\n.x-Window-danger:hover {\n  background-color: #b00;\n  border-color: #b00;\n}\n.x-Window-danger:active {\n  background-color: #550000;\n  border-color: #550000;\n}\n.x-Window-content {\n  overflow-y: auto;\n  flex-grow: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-Window",
	"compact": "x-Window-compact",
	"mandatoryBorder": "x-Window-mandatoryBorder",
	"bar": "x-Window-bar",
	"title": "x-Window-title",
	"controlButtons": "x-Window-controlButtons",
	"button": "x-Window-button",
	"danger": "x-Window-danger",
	"content": "x-Window-content"
};
module.exports = exports;
