// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-FloatView {\n  display: flex;\n}\n.x-FloatView svg {\n  width: 16px;\n  height: 16px;\n}\n.x-FloatView svg path {\n  stroke: white;\n}\n.x-FloatView-tabs {\n  position: relative;\n  border-right: 1px solid hsl(200, 6%, 38%);\n  background-color: hsl(200, 6%, 17%);\n  font-size: 13px;\n}\n.x-FloatView-tabs button {\n  display: block;\n  font-size: 13px;\n}\n.x-FloatView-folder {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.x-FloatView-folderContent {\n  flex: 1;\n  display: flex;\n  width: 235px;\n  overflow: auto;\n}\n.x-FloatView-folderContent > * {\n  flex: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "x-FloatView",
	"tabs": "x-FloatView-tabs",
	"folder": "x-FloatView-folder",
	"folderContent": "x-FloatView-folderContent"
};
module.exports = exports;
