// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".x-EntityList-emptySelection {\n  font-style: initial;\n  color: #BFBFBF;\n}\n.x-EntityList-entityRef {\n  background-color: #3a687d;\n  border-radius: 2px;\n  border: 1px solid #d2d0e0;\n  padding: 0 3px;\n  margin: 0 2px 2px 2px;\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n}\n.x-EntityList-entityRef .x-EntityList-rm {\n  padding-left: 2px;\n  background: none;\n  cursor: pointer;\n}\n.x-EntityList-entityRef .x-EntityList-rm:hover {\n  color: salmon;\n}\n.x-EntityList-entityRef .x-EntityList-rm:active {\n  color: red;\n}\n.x-EntityList-entityRef:hover .x-EntityList-editBtn {\n  display: flex;\n}\n.x-EntityList-entityLabel {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n.x-EntityList-editBtn {\n  display: none;\n  align-items: center;\n  position: absolute;\n  right: 0;\n  height: 100%;\n  padding: 0 2px;\n  background-color: #a9a91a;\n}\n.x-EntityList-editBtn:hover {\n  color: #feffcb;\n}\n.x-EntityList-editBtn:active {\n  color: yellow;\n}\n.x-EntityList-container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"emptySelection": "x-EntityList-emptySelection",
	"entityRef": "x-EntityList-entityRef",
	"rm": "x-EntityList-rm",
	"editBtn": "x-EntityList-editBtn",
	"entityLabel": "x-EntityList-entityLabel",
	"container": "x-EntityList-container"
};
module.exports = exports;
